<template>
  <div>
    <v-card class="ma-sm-1 mb-sm-6 mb-2 flatcard" color="grey lighten-5">
      <v-card-title>
        Passiva
      </v-card-title>
      <v-card-text>
        <v-alert
            type="info"
            color="primary"
            border="right"
            outlined
        >
          <small>
            Eigenkapital und Gesellschafterdarlehen mit Rangrücktritt sind bei den Passiva nicht zu berücksichtigen.
          </small>
        </v-alert>
        <v-form ref="form" lazy-validation>
          <v-container>
            <h3>I. Rückstellungen</h3>
            <BalanceSheetRow title="Rückstellungen" v-model="data.accruals" />

            <h3>II. Verbindlichkeiten</h3>
            <BalanceSheetRow title="Verbindlichkeiten aus Lieferungen u. Leistungen" v-model="data.tradeAccountLiabilities" />
            <BalanceSheetRow title="Verbindlichkeiten gegenüber Kreditinstituten" v-model="data.bankLiabilities" />
            <BalanceSheetRow title="Verbindlichkeiten gegenüber verbundenen Unternehmen" v-model="data.affiliateLiabilities" />
            <BalanceSheetRow title="Steuern und Abgaben" v-model="data.taxesAndDuties" />
            <BalanceSheetRow title="Löhne und Gehälter" v-model="data.wagesAndSalaries" />
            <BalanceSheetRow title="Sozialversicherungsbeiträge" v-model="data.socialSecurityContributions" />
            <BalanceSheetRow title="Sonstige Verbindlichkeiten" v-model="data.otherLiabilities" />
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
    <StepButtons :firstStep="firstStep" :lastStep="lastStep" @previous="$emit('previous')" @next="$emit('next')"/>
  </div>
</template>
<script>
import StepButtons from "@/components/StepButtons";
import BalanceSheetRow from "@/components/BalanceSheetRow";

export default {
  name: 'Liabilities',
  components: {StepButtons, BalanceSheetRow},
  props: {
    firstStep: {},
    lastStep: {},
    data: {},
  }
}
</script>
