<template>
  <div>
    <v-card class="ma-sm-1 mb-sm-6 mb-2 flatcard" color="grey lighten-5">
      <v-card-title>
        Aktiva
      </v-card-title>
      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-container>
            <h3>I. Ausstehende Einlagen</h3>
            <BalanceSheetRow title="Ausstehende Einlagen" v-model="data.outstandingDeposits" />
            <h3>II. Anlagevermögen</h3>
            <BalanceSheetRow title="Grundstücke und Gebäude, grundstücksgleiche Rechte" v-model="data.realEstate" />
            <BalanceSheetRow title="Maschinen, maschinelles Anlagevermögen" v-model="data.machineryEquipment" />
            <BalanceSheetRow title="Fuhrpark" v-model="data.vehicles" />
            <BalanceSheetRow title="Betriebs- und Geschäftsausstattung" v-model="data.officeEquipment" />
            <BalanceSheetRow title="Beteiligungen" v-model="data.investments" />
            <h3>III. Umlaufvermögen</h3>
            <h4>A. Vorratsvermögen</h4>
            <BalanceSheetRow title="Roh-, Hilfs- u. Betriebsstoffe" v-model="data.materialsAndSupplies" />
            <BalanceSheetRow title="Halbfertige Arbeiten" v-model="data.workInProgress" />
            <h4>B. Andere Gegenstände des Umlaufvermögens</h4>
            <BalanceSheetRow title="Forderungen aus Lieferungen u. Leistungen" v-model="data.tradeAccountClaims" />
            <BalanceSheetRow title="Sonstige Forderungen" v-model="data.otherClaims" />
            <BalanceSheetRow title="Kassenbestand" v-model="data.cash" />
            <BalanceSheetRow title="Guthaben bei Kreditinstituten" v-model="data.balances" />
            <BalanceSheetRow title="Schadensersatz- u. Erstattungsansprüche" v-model="data.reimbursements" />
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
    <StepButtons :firstStep="firstStep" :lastStep="lastStep" @previous="$emit('previous')" @next="$emit('next')"/>
  </div>
</template>
<script>
import StepButtons from "@/components/StepButtons";
import BalanceSheetRow from "@/components/BalanceSheetRow";

export default {
  name: 'Assets',
  components: {StepButtons, BalanceSheetRow},
  props: {
    firstStep: {},
    lastStep: {},
    data: {},
  }
}
</script>
