<template>
  <v-container fluid>
    <v-stepper v-model="step">
      <v-stepper-header>
        <v-stepper-step :complete="step > 1" step="1">
          Einleitung
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 2" step="2">
          Ihre finanzielle Situation heute
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 3" step="3">
          Ihre finanzielle Situation in 3 Wochen
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="4">
          Ihr Ergebnis
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1">
          <InsolvencyIntro @next="step = 2" :first-step="true"/>
        </v-stepper-content>
        <v-stepper-content step="2">
          <FinancialSituation :insolvencyData="form.insolvencyData"
                              @previous="step = 1" @next="step = 3"/>
        </v-stepper-content>
        <v-stepper-content step="3">
          <FinancialPerspective :insolvencyData="form.insolvencyData" :lastStep="true"
                              @previous="step = 2" @next="calculateResult"/>
        </v-stepper-content>

        <v-stepper-content step="4">
          <InsolvencyResultView :form="form" :result="result"
                                @reset="resetForm"
                                @startOverIndebtness="startOverIndebtness"/>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>
<script>
import InsolvencyIntro from "@/components/InsolvencyIntro";
import FinancialSituation from "@/components/FinancialSituation";
import FinancialPerspective from "@/components/FinancialPerspective";
import InsolvencyResultView from "@/components/InsolvencyResultView";
import axios from "axios";
import {createInsolvencyDataPayload} from "@/helper";

export default {
  name: 'InsolvencyForm',
  components: {InsolvencyIntro, FinancialSituation, FinancialPerspective,InsolvencyResultView},
  props: {
    form: {},
  },
  data() {
    return {
      step: 1,
      result: null
    }
  },
  methods: {
    resetForm: function () {
      this.result = null;
      this.step = 1;
    },
    startOverIndebtness: function () {
      console.log('startOverIndebtness');
      this.$emit('showOverIndebtness');
    },
    calculateResult: function () {
      let self = this;
      axios.post(`${process.env.VUE_APP_ENDPOINT}/validate/insolvency`,createInsolvencyDataPayload(this.form),{
        timeout: 10000
      })
          .then(function (response) {
            console.log(response);
            console.log("result", response.data.insolvencyStatus);
            self.result = response.data.insolvencyStatus.toLowerCase();
            self.step = 4;
          })
          .catch(function (error) {
            console.log(error);
            // TODO: show error message in toast
          });
    }
  }
}
</script>
<style>
.v-card__text, .v-card__title {
  word-break: normal; /* maybe !important  */
}
</style>
