<template>
    <v-row class="ma-0 mb-1 mb-sm-0 pa-1">
      <v-col class="ma-0 pa-0" cols="6">
        <v-btn color="secondary" @click="$emit('previous')" elevation="2" large v-if="!firstStep">Zurück</v-btn>
      </v-col>
      <v-col class="ma-0 pa-0" cols="6" align="right">
        <v-btn color="primary" @click="$emit('next')" elevation="2" large v-if="lastStep">
          <span class="d-none d-sm-inline">Ergebnis berechnen</span>
          <span class="d-xs d-sm-none">Ergebnis</span>
        </v-btn>
        <v-btn color="primary" @click="$emit('next')" elevation="2" :disabled="disabled" large v-else>
          <span class="d-none d-sm-inline">Nächster Schritt</span>
          <span class="d-xs d-sm-none">Weiter</span>
        </v-btn>
      </v-col>
    </v-row>
</template>
<script>
export default {
  name: 'StepButtons',
  components: {},
  props: {
    firstStep: {},
    lastStep: {},
    disabled: {},
  }
}
</script>
