import Vue from 'vue'
import App from './App.vue'
import vuetify from '@/plugins/vuetify'
import VueCurrencyInput from 'vue-currency-input'

const pluginOptions = {
  /* see config reference */
  globalOptions: { currency: 'EUR', locale:'de' }
}

Vue.config.productionTip = false
Vue.use(VueCurrencyInput, pluginOptions)

new Vue({
  vuetify,
  render: h => h(App),
}).$mount('#app')
