<template>
  <div>
    <v-card class="ma-sm-1 mb-sm-6 mb-2 flatcard" color="grey lighten-5">
      <v-card-title>
        Ihr Ergebnis
      </v-card-title>
      <v-card-text>
        <v-container class="ma-0 pa-0">
          <v-row v-if="result === 'red'" no-gutters>
            <v-col cols="12" class="text-center d-block d-sm-none">
              <v-sheet elevation="2" class="pa-2">
                <img src="../assets/red-vulture.png" style="width: 100%;"/>
              </v-sheet>
            </v-col>
            <v-col md="9" sm="8" cols="12" class="">
              <p>
                Sie sind aller Voraussicht nach zahlungsunfähig.
              </p>
              <p>
                Ihnen bleiben ab heute drei Wochen, um einen Antrag auf
                Eröffnung eines Insolvenzverfahrens zu stellen. Maßgeblich ist der Eingang des Insolvenzantrags beim
                zuständigen Insolvenzgericht. Sollten Sie Ihrer Antragspflicht nicht oder nicht rechtzeitig nachkommen,
                besteht die Gefahr einer Insolvenzverschleppung.
              </p>
              <p>
                Bitte beachten Sie, dass diesem Ergebnis eine kursorische Prüfung zugrunde liegt und der
                Insolvenzrechner eine professionelle Beratung nicht ersetzt.
              </p>
            </v-col>
            <v-col md="3" sm="4" cols="4" class="text-center d-none d-sm-block">
              <v-sheet elevation="2" class="pa-2 ml-2">
                <img src="../assets/red-vulture.png" style="width: 100%;"/>
              </v-sheet>
            </v-col>
          </v-row>
          <v-row v-if="result === 'yellow'" no-gutters>
            <v-col cols="12" class="text-center d-block d-sm-none">
              <v-sheet elevation="2" class="pa-2">
                <img src="../assets/yellow-vulture.png" style="width: 100%;"/>
              </v-sheet>
            </v-col>
            <v-col md="9" sm="8" cols="12" class="">
              <p>
                Es besteht eine Deckungslücke, die allerdings nicht größer als 10 % ist. Wenn es überwiegend
                wahrscheinlich ist, dass die Deckungslücke innerhalb von drei bis sechs Wochen beseitigt werden kann,
                dann liegt lediglich eine Zahlungsstockung und noch keine Zahlungsunfähigkeit vor. Gleichwohl reichen Ihre
                verfügbaren Zahlungsmittel aktuell nicht aus, um die fälligen Verbindlichkeiten zu begleichen. Sie
                sollten
                daher dringend professionellen Rat in Anspruch nehmen. Ehe man sich‘s versieht, ist die Schwelle
                zwischen Zahlungsstockung und Zahlungsunfähigkeit überschritten.
              </p>
              <p>
                Bitte beachten Sie, dass diesem Ergebnis eine kursorische Prüfung zugrunde liegt und der
                Insolvenzrechner eine professionelle Beratung nicht ersetzt.
              </p>
            </v-col>
            <v-col md="3" sm="4" cols="4" class="text-center d-none d-sm-block">
              <v-sheet elevation="2" class="pa-2 ml-2">
                <img src="../assets/yellow-vulture.png" style="width: 100%;"/>
              </v-sheet>
            </v-col>
          </v-row>
          <v-row v-if="result === 'lime'" no-gutters>
            <v-col cols="12" class="text-center d-block d-sm-none">
              <v-sheet elevation="2" class="pa-2">
                <img src="../assets/green-vulture.png" style="width: 100%;"/>
              </v-sheet>
            </v-col>
            <v-col md="9" sm="8" cols="12" class="mt-2 mt-sm-0">
              <p>
                So gerade eben sind Sie an einer bestehenden Deckunglücke vorbeigeschrammt. Eine Zahlungsunfähigkeit
                besteht daher zum aktuellen Zeitpunkt nicht. Gleichwohl haben Sie keinerlei Liquiditätsreserven. Bei
                größeren Zahlungsverpflichtungen könnte es schnell zu einer Deckungslücke und damit einer
                Zahlungsunfähigkeit kommen. Nehmen Sie daher besser frühzeitig professionellen Rat in Anspruch.
              </p>
              <p>
                Bitte beachten Sie, dass diesem Ergebnis eine kursorische Prüfung zugrunde liegt und der
                Insolvenzrechner eine professionelle Beratung nicht ersetzt.
              </p>
            </v-col>
            <v-col md="3" sm="4" cols="4" class="text-right d-none d-sm-block">
              <v-sheet elevation="2" class="pa-2 ml-2">
                <img src="../assets/green-vulture.png" style="width: 100%;"/>
              </v-sheet>
            </v-col>
          </v-row>
          <v-row v-if="result === 'green'" no-gutters>
            <v-col cols="12" class="text-center d-block d-sm-none">
              <v-sheet elevation="2" class="pa-2">
                <img src="../assets/green-vulture.png" style="width: 100%;"/>
              </v-sheet>
            </v-col>
            <v-col md="9" sm="8" cols="12" class="mt-2 mt-sm-0">
              <p>
                Glückwunsch! Sie sind nicht zahlungsunfähig. Liquiditätsreserven können aber schnell aufgebraucht sein,
                insbesondere wenn größere oder unerwartete Zahlungsverpflichtungen anstehen. Ehe man sich‘s versieht,
                besteht eine Deckungslücke, die zu einer Zahlungsunfähigkeit führen kann. Nehmen Sie daher besser
                frühzeitig professionellen Rat in Anspruch.
              </p>
              <p>
                Auch wenn eine Zahlungsunfähigkeit aktuell nicht gegeben ist, könnte eine Überschuldung vorliegen. Bei
                dem Vorliegen einer Überschuldung besteht unabhängig davon, ob Sie zahlungsunfähig sind, eine
                Insolvenzantragspflicht.
              </p>
              <p>
                Bitte beachten Sie, dass diesem Ergebnis eine kursorische Prüfung zugrunde liegt und der
                Insolvenzrechner eine professionelle Beratung nicht ersetzt.
              </p>
            </v-col>
            <v-col md="3" sm="4" cols="4" class="text-right d-none d-sm-block">
              <v-sheet elevation="2" class="pa-2">
                <img src="../assets/green-vulture.png" style="width: 100%;"/>
              </v-sheet>
            </v-col>
          </v-row>
          <v-container class="ma-0 mt-sm-2 pa-1 text-center" fluid>
            <v-row v-if="result !== 'red'">
              <v-col cols="12">
                <v-btn color="primary" class="mb-5" elevation="2" @click="$emit('startOverIndebtness')" large>
                  <span class="d-block d-sm-none">Überschuldung prüfen</span>
                  <span class="d-none d-sm-block">Jetzt auf Überschuldung prüfen</span>
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="result === 'red'">
              <v-col cols="12" sm="6">
                <RegistrationDialog :form="form" button-style="primary"/>
              </v-col>
              <v-col cols="12" sm="6">
                <NewsletterSignupDialog/>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12" sm="6">
                <RegistrationDialog :form="form" button-style="secondary"/>
              </v-col>
              <v-col cols="12" sm="6">
                <NewsletterSignupDialog/>
              </v-col>
            </v-row>
          </v-container>
        </v-container>
      </v-card-text>
    </v-card>
    <v-container class="ma-0 pa-1 text-center" fluid>
      <v-btn color="grey" text rounded @click="$emit('reset')">Gleich nochmal!</v-btn>
    </v-container>
  </div>
</template>
<script>
import RegistrationDialog from "@/components/RegistrationDialog";
import NewsletterSignupDialog from "@/components/NewsletterSignupDialog";

export default {
  name: 'InsolvencyResultView',
  components: {RegistrationDialog, NewsletterSignupDialog},
  props: {
    form: {},
    result: {}
  }
}
</script>
