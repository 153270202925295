<template>
  <v-app>
    <v-main>
      <InsolvencyForm v-if="status==='insolvencyProcess'" :form="form" @showOverIndebtness="startOverIndebtness"/>
      <OverIndebtnessForm v-if="status==='overIndebtnessProcess'" :form="form" @startover="restartProcess"/>

      <v-footer class="font-weight-medium">
        <v-row justify="center" no-gutters>
          <v-col class="text-center" cols="12">
            <small>Ihre Daten werden sicher verschlüsselt und vertraulich behandelt.</small>
          </v-col>
<!--          <v-btn color="secondary" text rounded>Impressum</v-btn>-->
<!--          <v-btn color="secondary" text rounded>Rechtliche Hinweise</v-btn>-->
<!--          <v-btn color="secondary" text rounded>Nutzungsbedingungen</v-btn>-->
<!--          <v-btn color="secondary" text rounded>Datenschutzerklärung</v-btn>-->
<!--          <v-col color="secondary" class="text-center" cols="12">-->
<!--            <small style="color: rgb(158, 158, 158);">&copy; {{ year }} Wega Lyrae Business Solutions</small>-->
<!--          </v-col>-->
        </v-row>
      </v-footer>
    </v-main>
  </v-app>
</template>

<script>

import InsolvencyForm from "@/components/InsolvencyForm";
import OverIndebtnessForm from "@/components/OverIndebtnessForm";

export default {
  name: 'App',
  components: {InsolvencyForm, OverIndebtnessForm},
  data() {
    return {
      status: "insolvencyProcess",
      form: {
        baseData: {
          company: "",
          companyType: "",
          ceo: "",
          street: "",
          zipcode: "",
          city: "",
          phone: "",
          email: "",
          website: "",
          preferredContactType: "Email",
          preferredContactTime: "None",
          newsletter: false
        },
        insolvencyData: {
          currentDebt: 0.0,
          balance: 0.0,
          cash: 0.0,
          creditLimit: 0.0,
          expectedDebt: 0.0,
          financialDeposits: 0.0,
          financialPayments: 0.0,
          profitDeposits: 0.0,
          profitPayments: 0.0,
          currentDebtOverdue: false,
          futureDebtOverdue: false,
        },
        assets: {
          outstandingDeposits: 0.0,
          realEstate: 0.0,
          machineryEquipment: 0.0,
          vehicles: 0.0,
          officeEquipment: 0.0,
          investments: 0.0,
          materialsAndSupplies: 0.0,
          workInProgress: 0.0,
          tradeAccountClaims: 0.0,
          otherClaims: 0.0,
          cash: 0.0,
          balances: 0.0,
          reimbursements: 0.0
        },
        liabilities: {
          accruals: 0.0,
          tradeAccountLiabilities: 0.0,
          bankLiabilities: 0.0,
          affiliateLiabilities: 0.0,
          taxesAndDuties: 0.0,
          wagesAndSalaries: 0.0,
          socialSecurityContributions: 0.0,
          otherLiabilities: 0.0
        }
      }
    }
  },
  methods: {
    startOverIndebtness: function () {
      console.log('startOverIndebtness (App)');
      this.status = 'overIndebtnessProcess';
    },
    restartProcess: function () {
      console.log('restartProcess (App)');
      this.status = 'insolvencyProcess';
    },
  },
  computed: {
    year: function () {
      return (new Date()).getFullYear();
    },
  },
}
</script>

<style>
body {
  background: #f5efe0;
}

.v-card__text, .v-card__title {
  word-break: normal; /* maybe !important  */
}
@media screen and (max-width: 675.98px) {
  .container {
    padding: 2px !important;
  }
  .v-stepper__content {
    padding: 0 !important;
  }
  .flatcard {
    box-shadow: none !important;
    border-radius: 0 !important;
  }
}
</style>
