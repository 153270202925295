<template>
  <div>
    <v-card class="ma-sm-1 mb-sm-6 mb-2 flatcard" color="grey lighten-5">
      <v-card-title>
        Zahlungsunfähigkeit
      </v-card-title>
      <v-card-text>
        <p>
          In der Unternehmenskrise ist die Geschäftsführung (z.B.: GmbH, UG, GmbH & Co. KG) bzw. der Vorstand (z.B.: AG)
          verpflichtet, in regelmäßigen Abständen die Zahlungsfähigkeit zu prüfen.
        </p>
        <p>
          Bei der Prüfung ist zunächst der Liquiditätsstatus zu ermitteln und für den Fall einer Deckungslücke ein
          Finanzplan über den Zeitraum von 3 Wochen aufzustellen.
        </p>
        <p>
          Geben Sie daher bitte im Folgenden Ihre aktuellen Werte bezogen auf Ihre Finanzsituation heute und sodann
          bezogen auf Ihre Finanzsituation prognostiziert innerhalb von 3 Wochen an.
        </p>
      </v-card-text>
    </v-card>
    <StepButtons :firstStep="firstStep" :lastStep="lastStep" @previous="$emit('previous')" @next="$emit('next')"/>
  </div>
</template>
<script>
import StepButtons from "@/components/StepButtons";

export default {
  name: 'InsolvencyIntro',
  components: {StepButtons},
  props: {
    firstStep: {},
    lastStep: {},
    data: {},
  }
}
</script>
