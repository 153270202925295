<template>
  <div>
    <v-card class="ma-sm-1 mb-sm-6 mb-2 flatcard" color="grey lighten-5">
      <v-card-title>
        Finanzsituation in 3 Wochen
      </v-card-title>
      <v-card-text>
        <p class="d-inline d-sm-none">
          Bitte beantworten Sie folgende Fragen zur finanziellen Situation in 3 Wochen:
        </p>
        <v-form ref="form" lazy-validation>
          <v-container>
            <v-row>
              <v-col sm="10" cols="9" class="ma-0">
                <span class="text-subtitle-1">Bestehen bereits zum heutigen Tag überfällige Verbindlichkeiten?</span>
              </v-col>
              <v-col sm="2" cols="3" class="ma-0 pa-0">
                <v-switch v-model="insolvencyData.futureDebtOverdue" label="Ja" inset></v-switch>
              </v-col>
              <v-col cols="12" v-if="insolvencyData.currentDebtOverdue && insolvencyData.futureDebtOverdue">
                <v-alert
                    type="info"
                    color="primary"
                    border="right"
                    outlined
                >
                  <small>
                    Die aktuell fälligen Verbindlichkeiten, die Sie bereits angegeben haben, sind hier nicht noch
                    einmal zu berücksichtigen. Es sind hier aber alle weiteren, innerhalb des 3-Wochen-Zeitraums fällig
                    werdenden Verbindlichkeiten vollständig anzugeben.
                  </small>
                </v-alert>
              </v-col>
            </v-row>
            <v-row v-if="insolvencyData.futureDebtOverdue">
              <v-col sm="8" cols="12">
                <v-text-field v-model="insolvencyData.expectedDebt"
                              required="overdue"
                              label="Höhe der überfälligen Verbindlichkeiten" dense v-currency/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="ma-0 mt-5">
                <span class="text-subtitle-1">Bitte geben Sie nachfolgend die Daten zum Stichtag in 3 Wochen an:</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="6" cols="12">
                <v-text-field v-model="insolvencyData.financialDeposits" label="Finanzeinzahlungen" hint="Kapital-, Kreditaufnahme, Zinseinnahmen" dense v-currency/>
              </v-col>
              <v-col sm="6" cols="12">
                <v-text-field v-model="insolvencyData.profitDeposits" label="Erfolgseinzahlungen" hint="aus Umsatz inkl. Ust., sonstige Einzahlungen" dense v-currency/>
              </v-col>
              <v-col sm="6" cols="12">
                <v-text-field v-model="insolvencyData.financialPayments" label="Finanzauszahlungen" hint="Entnahmen, Kapitalrückzahlungen, Tilgung, Zinszahlungen, Steuerzahlungen inkl. Ust., Investitionen" dense v-currency/>
              </v-col>
              <v-col sm="6" cols="12">
                <v-text-field v-model="insolvencyData.profitPayments" label="Erfolgsauszahlungen" hint="Nettolöhne, Personalabgaben, Lohnsteuer, Miete/Raumkosten, Werbung/Marketing, Versicherungen, Materialaufwand, sonstige Auszahlungen" dense v-currency/>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
    <StepButtons :firstStep="firstStep" :lastStep="lastStep" @previous="$emit('previous')" @next="$emit('next')"/>
  </div>
</template>
<script>
import StepButtons from "@/components/StepButtons";

export default {
  name: 'FinancialPerspective',
  components: {StepButtons},
  props: {
    insolvencyData: {},
    firstStep: {},
    lastStep: {}
  },
  data() {
    return {
      overdue: false
    }
  },
  mounted() {
    this.overdue = this.insolvencyData.expectedDebt !== 0;
  }
}
</script>
