<template>
  <v-dialog
      v-model="newsletterForm"
      persistent
      transition="dialog-bottom-transition"
      max-width="390"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="secondary" elevation="1" v-bind="attrs" v-on="on" large x-sm-large>
        Newsletter abonnieren
      </v-btn>
    </template>
    <v-card color="grey lighten-5">
      <v-card-title class="headline">
        Newsletter abonnieren
      </v-card-title>
      <v-form ref="form" lazy-validation @submit.prevent="doNewsletterRegistration" v-if="!success">
        <v-overlay absolute :value="showOverlay">
          <v-progress-circular
              :size="70"
              :width="7"
              color="primary"
              indeterminate
          ></v-progress-circular>
        </v-overlay>
        <v-card-text>
          <v-container>
            Bitte tragen Sie Ihren Namen und E-Mail-Adresse ein und klicken Sie dann auf "Jetzt anmelden".
          </v-container>
        </v-card-text>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field filled v-model="name" label="Name" dense :counter="50" maxlength="50"
                              :rules="nameRules"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field filled v-model="email" label="E-Mail" dense :counter="80" maxlength="80"
                              :rules="emailRules"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="text-center">
                <v-btn color="primary" type="submit" large :disabled="!isValid">
                  Jetzt anmelden
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="text-center">
                <v-btn color="secondary" text @click="newsletterForm = false">
                  Abbrechen
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-form>
      <v-sheet width="100%" height="100%" min-height="300px" class="pa-5 text-center" v-else>
        <v-icon size="90" class="mb-5" color="light-green">mdi-email-check-outline</v-icon>
        <p class="mb-5">
          Vielen Dank - Bitte schließen Sie die Anmeldung ab,
          in dem Sie auf den Link klicken, den wir Ihnen gerade per Email gesendet haben.
        </p>
        <v-btn color="primary" @click="newsletterForm=false">OK</v-btn>
      </v-sheet>
    </v-card>
    <v-snackbar v-model="showError" timeout="3000">
      {{ error }}
      <template v-slot:action="{ attrs }">
        <v-btn
            color="blue"
            text
            v-bind="attrs"
            @click="showError = false"
        >
          OK
        </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>
<script>
import axios from "axios";

export default {
  name: 'NewsletterSignupDialog',
  components: {},
  props: {},
  data: () => ({
    success: false,
    showOverlay: false,
    showError: false,
    error: '',
    newsletterForm: false,
    valid: false,
    name: '',
    email: '',
    nameRules: [
      v => !!v || 'Name ist ein Pflichtfeld',
    ],
    emailRules: [
      v => !!v || 'E-Mail ist ein Pflichtfeld',
      v => /.+@.+\..+/.test(v) || 'Bitte geben Sie eine gültige E-Mail-Adresse an',
    ],
  }),
  computed: {
    isValid: function () {
      return this.name !== ''
          && this.email !== ''
          ;
    }
  },
  methods: {
    doNewsletterRegistration: function () {
      console.log("doNewsletterRegistration");
      this.showOverlay = true;
      let self = this;
      setTimeout(function(){
        axios.post(`${process.env.VUE_APP_ENDPOINT}/newsletter/subscribe`, {
          name: self.name,
          email: self.email
        }, {
          timeout: 10000,
        })
            .then(function (response) {
              console.log(response);
              self.success = true;
            })
            .catch(function (error) {
              console.log(error);
              self.error = error;
              self.showError = true;
            })
            .finally(() => this.showOverlay = false)
        ;
      }, 2000);
    }
  }
}
</script>
