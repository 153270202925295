<template>
  <div>
    <v-card class="ma-sm-1 mb-sm-6 mb-2 flatcard" color="grey lighten-5">
      <v-card-title>
        Ihr Ergebnis
      </v-card-title>
      <v-card-text>
        <v-container class="ma-0 pa-0">
          <v-row v-if="result === 'red'" no-gutters>
            <v-col cols="12" class="text-center d-block d-sm-none">
              <v-sheet elevation="2" class="pa-2">
                <img src="../assets/red-vulture.png" style="width: 100%;"/>
              </v-sheet>
            </v-col>
            <v-col md="9" sm="8" cols="12" class="mt-2 mt-sm-0">
              <p>
                Die Überschuldungsbilanz zeigt, dass Ihre Verbindlichkeiten Ihr vorhandenes Vermögen übersteigen. Nur bei
                einer positiven Fortführungsprognose besteht keine Überschuldung und keine Insolvenzantragspflicht. Im
                Falle einer negativen Fortführungsprognose sind Sie verpflichtet, innerhalb von sechs Wochen einen Antrag
                auf Eröffnung eines Insolvenzverfahrens zu stellen. Nehmen Sie bitte umgehend professionellen Rat zur
                Erstellung Ihrer Fortführungsprognose in Anspruch.
              </p>
              <p>
                Bitte beachten Sie, dass diesem Ergebnis eine kursorische Prüfung zugrunde liegt und der
                Insolvenzrechner eine professionelle Beratung nicht ersetzt.
              </p>
              <v-container class="ma-0 mt-sm-5 pa-1 text-center" fluid>
                <v-col cols="12">
                  <RegistrationDialog :form="form" button-style="primary"/>
                </v-col>
                <v-col cols="12">
                  <NewsletterSignupDialog />
                </v-col>
              </v-container>
            </v-col>
            <v-col md="3" sm="4" cols="4" class="text-center d-none d-sm-block">
              <v-sheet elevation="2" class="pa-2 ml-2">
                <img src="../assets/red-vulture.png" style="width: 100%;"/>
              </v-sheet>
            </v-col>
          </v-row>
          <v-row v-if="result === 'lime'" no-gutters>
            <v-col cols="12" class="text-center d-block d-sm-none">
              <v-sheet elevation="2" class="pa-2">
                <img src="../assets/yellow-vulture.png" style="width: 100%;"/>
              </v-sheet>
            </v-col>
            <v-col md="9" sm="8" cols="12" class="mt-2 mt-sm-0">
              <p>
                So gerade eben deckt sich Ihr vorhandenes Vermögen mit Ihren Verbindlichkeiten. Auch wenn aktuell nicht
                von einer Überschuldung gesprochen werden kann, kann sich dies rasch ändern. Nehmen Sie daher besser
                frühzeitig professionellen Rat in Anspruch und lassen Sie vorsorglich eine Fortführungsprognose
                erstellen.
              </p>
              <p>
                Bitte beachten Sie, dass diesem Ergebnis eine kursorische Prüfung zugrunde liegt und der
                Insolvenzrechner eine professionelle Beratung nicht ersetzt.
              </p>
              <v-container class="ma-0 mt-sm-5 pa-1 text-center" fluid>
                <v-col cols="12">
                  <RegistrationDialog :form="form" button-style="primary"/>
                </v-col>
                <v-col cols="12">
                  <NewsletterSignupDialog />
                </v-col>
              </v-container>
            </v-col>
            <v-col md="3" sm="4" cols="4" class="text-center d-none d-sm-block">
              <v-sheet elevation="2" class="pa-2 ml-2">
                <img src="../assets/yellow-vulture.png" style="width: 100%;"/>
              </v-sheet>
            </v-col>
          </v-row>
          <v-row v-if="result === 'green'" no-gutters>
            <v-col cols="12" class="text-center d-block d-sm-none">
              <v-sheet elevation="2" class="pa-2">
                <img src="../assets/green-vulture.png" style="width: 100%;"/>
              </v-sheet>
            </v-col>
            <v-col md="9" sm="8" cols="12" class="mt-2 mt-sm-0">
              <p>
                Glückwunsch! Sie sind nicht überschuldet. Es ist aber stets geboten, den sichersten Weg einzuschlagen
                und vorbeugend professionellen Rat in Anspruch zu nehmen. Manchmal gerät ein Unternehmen ohne
                vorwarnende Anzeichen in Schieflage. Für einen solchen Fall sollte man gewappnet sein.
              </p>
              <p>
                Bitte beachten Sie, dass diesem Ergebnis eine kursorische Prüfung zugrunde liegt und der
                Insolvenzrechner eine professionelle Beratung nicht ersetzt.
              </p>
              <v-container class="ma-0 mt-sm-5 pa-1 text-center" fluid>
                <v-col cols="12">
                  <RegistrationDialog :form="form" button-style="primary"/>
                </v-col>
                <v-col cols="12">
                  <NewsletterSignupDialog />
                </v-col>
              </v-container>
            </v-col>
            <v-col md="3" sm="4" cols="4" class="text-center d-none d-sm-block">
              <v-sheet elevation="2" class="pa-2 ml-2">
                <img src="../assets/green-vulture.png" style="width: 100%;"/>
              </v-sheet>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
    <v-container class="ma-0 pa-1 text-center" fluid>
      <v-btn color="grey" text rounded @click="$emit('reset')">Gleich nochmal!</v-btn>
    </v-container>
  </div>
</template>
<script>
import RegistrationDialog from "@/components/RegistrationDialog";
import NewsletterSignupDialog from "@/components/NewsletterSignupDialog";

export default {
  name: 'OverIndebtnessResultView',
  components: {RegistrationDialog, NewsletterSignupDialog},
  props: {
    form: {},
    result: {}
  }
}
</script>
