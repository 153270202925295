import {parse} from "vue-currency-input";

export function scrollParentToTop() {
    if ('parentIFrame' in window) {
        window['parentIFrame'].scrollToOffset(0,0);
    }
}

export function createInsolvencyDataPayload(form) {
    return{
        "insolvencyData": {
            "currentDebt": form.insolvencyData.currentDebtOverdue ? nullToZero(parse(form.insolvencyData.currentDebt)) : 0,
            "balance": nullToZero(parse(form.insolvencyData.balance)),
            "cash": nullToZero(parse(form.insolvencyData.cash)),
            "creditLimit": nullToZero(parse(form.insolvencyData.creditLimit)),
            "expectedDebt": form.insolvencyData.futureDebtOverdue ? nullToZero(parse(form.insolvencyData.expectedDebt)) : 0,
            "financialDeposits": nullToZero(parse(form.insolvencyData.financialDeposits)),
            "financialPayments": nullToZero(parse(form.insolvencyData.financialPayments)),
            "profitDeposits": nullToZero(parse(form.insolvencyData.profitDeposits)),
            "profitPayments": nullToZero(parse(form.insolvencyData.profitPayments)),
        }
    };
}
export function createIndebtnessDataPayload(form) {
    return {
        "assets": {
            "outstandingDeposits": nullToZero(parse(form.assets.outstandingDeposits)),
            "realEstate": nullToZero(parse(form.assets.realEstate)),
            "machineryEquipment": nullToZero(parse(form.assets.machineryEquipment)),
            "vehicles": nullToZero(parse(form.assets.vehicles)),
            "officeEquipment": nullToZero(parse(form.assets.officeEquipment)),
            "investments": nullToZero(parse(form.assets.investments)),
            "materialsAndSupplies": nullToZero(parse(form.assets.materialsAndSupplies)),
            "workInProgress": nullToZero(parse(form.assets.workInProgress)),
            "tradeAccountClaims": nullToZero(parse(form.assets.tradeAccountClaims)),
            "otherClaims": nullToZero(parse(form.assets.otherClaims)),
            "cash": nullToZero(parse(form.assets.cash)),
            "balances": nullToZero(parse(form.assets.balances)),
            "reimbursements": nullToZero(parse(form.assets.reimbursements))
        },
        "liabilities": {
            "accruals": nullToZero(parse(form.liabilities.accruals)),
            "tradeAccountLiabilities": nullToZero(parse(form.liabilities.tradeAccountLiabilities)),
            "bankLiabilities": nullToZero(parse(form.liabilities.bankLiabilities)),
            "affiliateLiabilities": nullToZero(parse(form.liabilities.affiliateLiabilities)),
            "taxesAndDuties": nullToZero(parse(form.liabilities.taxesAndDuties)),
            "wagesAndSalaries": nullToZero(parse(form.liabilities.wagesAndSalaries)),
            "socialSecurityContributions": nullToZero(parse(form.liabilities.socialSecurityContributions)),
            "otherLiabilities": nullToZero(parse(form.liabilities.otherLiabilities))
        },
    };
}

export function nullToZero(value) {
    return value === null ? 0.0 : value;
}
