<template>
  <v-row>
    <v-col sm="6" cols="12" class="d-none d-sm-block">
      <label>{{title}}</label>
    </v-col>
    <v-col sm="6" cols="12">
      <v-text-field :value="value" @input="$emit('input', $event)" :label="label" dense v-currency/>
    </v-col>
  </v-row>
</template>
<script>

export default {
  name: 'BalanceSheetRow',
  props: {
    title: {},
    hint: {},
    value: {},
  },
  computed: {
    label: function () {
      return this.hint ? this.hint : this.title;
    }
  },
}
</script>
