<template>
  <v-container fluid>
    <v-stepper v-model="step" alt-labels>
      <v-stepper-header>
        <v-stepper-step :complete="step > 1" step="1">

        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 2" step="2">
          Aktiva
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 3" step="3">
          Passiva
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="4">
          Ergebnis
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1">
          <IndebtnessIntro @next="step = 2" first-step="true"/>
        </v-stepper-content>
        <v-stepper-content step="2">
          <Assets :data="form.assets" @previous="step = 1" @next="step = 3"/>
        </v-stepper-content>
        <v-stepper-content step="3">
          <Liabilities :data="form.liabilities" @previous="step = 2" @next="calculateResult" last-step="true"/>
        </v-stepper-content>
        <v-stepper-content step="4">
          <OverIndebtnessResultView :form="form" :result="result" @reset="resetForm" />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>
<script>
import Liabilities from "@/components/Liabilities";
import Assets from "@/components/Assets";
import IndebtnessIntro from "@/components/IndebtnessIntro";
import OverIndebtnessResultView from "@/components/OverIndebtnessResultView";
import axios from "axios";
import {createIndebtnessDataPayload} from "@/helper";

export default {
  name: 'OverIndebtnessForm',
  components: {IndebtnessIntro, Assets, Liabilities, OverIndebtnessResultView},
  props: {
    form: {},
  },
  data() {
    return {
      step: 1,
      result: null
    }
  },
  methods: {
    resetForm: function () {
      this.result = null;
      this.step = 1;
      this.$emit('startover');
    },

    calculateResult: function () {
      let self = this;
      axios.post(`${process.env.VUE_APP_ENDPOINT}/validate/indebtness`, createIndebtnessDataPayload(self.form), {
        timeout: 10000
      })
          .then(function (response) {
            console.log(response);
            console.log("result", response.data.indebtnessStatus);
            self.result = response.data.indebtnessStatus.toLowerCase();
            self.step = 4;
          })
          .catch(function (error) {
            console.log(error);
            // TODO: show error message in toast
          });
    },
  }
}
</script>
<style>
.v-card__text, .v-card__title {
  word-break: normal; /* maybe !important  */
}

.v-stepper__content {

}
</style>
